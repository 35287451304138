import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import CriticalAnnouncementFeed from "./critical-announcement"

import DashLogoCircular from "../../logos/dash-logo-circular"

const SiteHeaderGeneric = props => {
  const [criticalAnnouncementOffset, setCriticalAnnouncementOffset] = useState(
    0
  )
  return (
    <>
      <CriticalAnnouncementFeed
        location="all"
        criticalAnnouncementOffset={criticalAnnouncementOffset}
        setCriticalAnnouncementOffset={setCriticalAnnouncementOffset}
      />
      <header className="flex h-16 w-full items-center bg-primary px-4">
        <Link to="/" className="w-14">
          <DashLogoCircular
            width="100%"
            bgColor="text-secondary"
            textColor="text-white"
          />
          <span className="tw-sr-only">Return to Dash Home</span>
        </Link>
        {props.pageTitle !== "" && (
          <h1 className="ml-4 text-xl font-bold text-white md:text-3xl">
            {props.pageTitle}
          </h1>
        )}
      </header>
    </>
  )
}

SiteHeaderGeneric.propTypes = {
  pageTitle: PropTypes.string,
}

SiteHeaderGeneric.defaultProps = {
  pageTitle: "",
}

export default SiteHeaderGeneric
